<!--
 * @Author: your name
 * @Date: 2021-06-06 16:22:13
 * @LastEditTime: 2021-06-07 22:09:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \server\src\views\login\login.vue
-->

<template>
  <el-dialog
  customClass="login-dialog"
  title="请登录"
  v-model="dialogVisible"
  width="30%"
  center
  :show-close='false'
  :closeOnClickModal='false'
  >
  <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="账号">
    <el-input v-model="form.name"></el-input>
  </el-form-item>
  <el-form-item label="密码">
    <el-input v-model="form.password"></el-input>
  </el-form-item>
</el-form>
  <template #footer>
    <span class="dialog-footer">
       <el-button type="primary" @click="onConfirm1">注 册</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
  </template>
</el-dialog>
</template>


<script>
import {login,addUser} from "@/api/user";
import {getConfig} from "@/api/config";
 export default {
    data() {
      return {
        dialogVisible: true,
        form:{
           name: '',
           password: '',
        }
      };
    },
    mounted(){
      this.init()
    },
    methods: {
      init(){
        getConfig().then(res=>{
          let list = res.data[0]
          this.$store.commit("setQiniu", JSON.parse(list.qiniu));
          this.$store.commit("setConfig", list);
        })
        if(this.$store.state.loginName.username){
            
             this.$router.push({name:'index'})
        }
      },
      onConfirm1(){
          addUser(this.form).then(res=>{
            if(res.status === 200){
              this.$message.success('创建成功！请保存好账号密码');
            }else if(res.status === -1){
              this.$message.error('用户名已存在');
            }
          })
      },
      onConfirm(){
        if(this.form.name === ''){
          this.$message.error('请输入账号！');
        }else if(this.form.password === ''){
          this.$message.error('请输入密码！');
        }else{
          login(this.form).then(res=>{
            if(res === -1){
               this.$message.error('账号不存在！');
            }else if(res === 0){
               this.$message.error('密码错误！');
            }else{
              this.$store.commit("setLonigName", res.data[0]);
              this.$router.push({name:'index'})
            }
          })
        }
      }
    }
  };
</script>
<style lang="less" >
.login-dialog{
  min-width: 400px;
}
</style>



